{
  "en": {
    "activerecord": {
      "errors": {
        "models": {
          "device": {
            "attributes": {
              "base": {
                "device_already_assigned_to_user": "Asset is already assigned",
                "device_is_archived": "Asset is archived",
                "user_cant_be_archived": "This person is archived and cannot be assigned to"
              },
              "lease_expiry_date": {
                "lease_expiry_date_before_purchased_on": "can't be earlier than purchased on"
              },
              "purchased_on": {
                "must_be_in_certain_range": "must be after %{date_from} and before %{date_to}"
              },
              "serial_number": {
                "duplicated": "is already in use"
              },
              "state": {
                "assigned_entities": "Asset has assigned entities"
              },
              "state_event": {
                "assigned_entities": "Asset has assigned entities"
              },
              "warranty_expiry": {
                "cant_be_earlier_than_purchased_on": "can't be earlier than purchased on"
              }
            }
          }
        }
      }
    },
    "web": {
      "apps": {
        "bulk_action_buttons": {
          "bulk_discover": {
            "one": "Move 1 App to Discovered",
            "other": "Move %{count} Apps to Discovered",
            "zero": "Move 0 App to Discovered"
          },
          "bulk_ignore": {
            "one": "Ignore 1 App",
            "other": "Ignore %{count} Apps",
            "zero": "Ignore 0 App"
          },
          "bulk_onboard": {
            "one": "Move 1 App to Onboarding",
            "other": "Move %{count} Apps to Onboarding",
            "zero": "Move 0 App to Onboarding"
          },
          "bulk_unapprove": {
            "one": "Unapprove 1 App",
            "other": "Unapprove %{count} Apps",
            "zero": "Unapprove 0 App"
          }
        },
        "bulk_action_progress_panel": {
          "select_all_link": "Select all %{count} Apps",
          "select_all_selected_html": "All <strong>%{selected_apps_count}</strong> Apps on this page are selected."
        },
        "detected_users": {
          "bulk_action_buttons": {
            "bulk_assign": {
              "one": "Assign to 1 User",
              "other": "Assign to %{count} Users",
              "zero": "Assign to No Users"
            },
            "bulk_unassign_from_all": {
              "one": "Unassign all Licenses from 1 User",
              "other": "Unassign all Licenses from %{count} Users",
              "zero": "Unassign all Licenses from No Users"
            }
          }
        },
        "discovered_filter": {
          "business_owners": "Business Owners",
          "export_apps_to_csv": "Export Apps to CSV",
          "license_administrators": "License Administrators",
          "licenses": "Licenses",
          "sorted_by": "Sorted By",
          "sources": "Sources",
          "start_sync": "Start sync",
          "synchronizing": "Synchronizing"
        },
        "onboardings": {
          "questionnaire": {
            "awaiting": {
              "one": "Awaiting response: Expires 1 Day",
              "other": "Awaiting response: Expires %{count} Days",
              "zero": "Awaiting response: Expired"
            },
            "completed_by_third_party": "Completed by third party",
            "in_progress": {
              "one": "In progress: Expires 1 Day",
              "other": "In progress: Expires %{count} Days",
              "zero": "In progress: Expired"
            },
            "questionnaire_invitation_copied": "Questionnaire invitation copied to the clipboard",
            "questions_completed": "Questions Completed",
            "reshare": "Re-share Questionnaire",
            "return_to_edit": "Return to edit",
            "revoke_invitation": "Revoke Invitation",
            "share": "Share Questionnaire",
            "show_activity": "Show Activity",
            "view_invite": "View invite details"
          }
        }
      },
      "asset_sources": {
        "shared": {
          "bulk_action_buttons": {
            "clear_selection": "Clear Selection",
            "ignore_asset_sources": {
              "one": "Ignore %{count} Asset",
              "other": "Ignore %{count} Assets",
              "zero": "Ignore 0 Assets"
            },
            "unignore_asset_sources": {
              "one": "Move to Detected %{count} Asset",
              "other": "Move to Detected %{count} Assets",
              "zero": "Move to Detected 0 Assets"
            },
            "unlink_and_ignore_asset_sources": {
              "one": "Unlink & Ignore %{count} Asset",
              "other": "Unlink & Ignore %{count} Assets",
              "zero": "Unlink & Ignore 0 Assets"
            },
            "unlink_asset_sources": {
              "one": "Unlink %{count} Asset",
              "other": "Unlink %{count} Assets",
              "zero": "Unlink 0 Assets"
            }
          }
        }
      },
      "companies": {
        "departments": {
          "bulk_action_buttons": {
            "bulk_archive": {
              "one": "Archive 1 Department",
              "other": "Archive %{count} Departments",
              "zero": "Archive 0 Departments"
            }
          },
          "bulk_action_progress_panel": {
            "completed_for": {
              "one": "Bulk action completed successfully. Processed 1 entity out of %{total_count}.",
              "other": "Bulk action completed successfully. Processed %{count} entities out of %{total_count}.",
              "zero": "Bulk action completed successfully. Processed 0 entities out of %{total_count}."
            },
            "errors": {
              "one": "1 error occurred",
              "other": "%{count} errors occurred",
              "zero": "0 errors occurred"
            },
            "select_all_link": "Select all %{count} Departments",
            "select_all_selected_html": "All <strong>%{selected_departments_count}</strong> Departments on this page are selected."
          }
        },
        "inspection_types": {
          "bulk_action_buttons": {
            "destroy_inspection_types": {
              "one": "Remove 1 Reminder Type",
              "other": "Remove %{count} Reminder Types",
              "zero": "Remove 0 Reminder Types"
            },
            "edit_inspection_types": {
              "one": "Edit 1 Reminder Type",
              "other": "Edit %{count} Reminder Types",
              "zero": "Edit 0 Reminder Types"
            }
          }
        },
        "legal_entities": {
          "bulk_action_buttons": {
            "bulk_archive": {
              "one": "Archive 1 Legal Entity",
              "other": "Archive %{count} Legal Entities",
              "zero": "Archive 0 Legal Entities"
            }
          },
          "bulk_action_progress_panel": {
            "completed_for": {
              "one": "Bulk action completed successfully. Processed 1 entity out of %{total_count}.",
              "other": "Bulk action completed successfully. Processed %{count} entities out of %{total_count}.",
              "zero": "Bulk action completed successfully. Processed 0 entities out of %{total_count}."
            },
            "errors": {
              "one": "1 error occurred",
              "other": "%{count} errors occurred",
              "zero": "0 errors occurred"
            },
            "select_all_link": "Select all %{count} Legal Entities",
            "select_all_selected_html": "All <strong>%{selected_legal_entities_count}</strong> Legal Entities on this page are selected."
          }
        },
        "locations": {
          "bulk_action_buttons": {
            "bulk_archive": {
              "one": "Archive 1 Location",
              "other": "Archive %{count} Locations",
              "zero": "Archive 0 Locations"
            }
          },
          "bulk_action_progress_panel": {
            "completed_for": {
              "one": "Bulk action completed successfully. Processed 1 entity out of %{total_count}.",
              "other": "Bulk action completed successfully. Processed %{count} entities out of %{total_count}.",
              "zero": "Bulk action completed successfully. Processed 0 entities out of %{total_count}."
            },
            "errors": {
              "one": "1 error occurred",
              "other": "%{count} errors occurred",
              "zero": "0 errors occurred"
            },
            "select_all_link": "Select all %{count} Locations",
            "select_all_selected_html": "All <strong>%{selected_locations_count}</strong> Locations on this page are selected."
          }
        },
        "sublocations": {
          "bulk_action_buttons": {
            "bulk_archive": {
              "one": "Archive 1 Sublocation",
              "other": "Archive %{count} Sublocations",
              "zero": "Archive 0 Sublocations"
            }
          },
          "bulk_action_progress_panel": {
            "completed_for": {
              "one": "Bulk action completed successfully. Processed 1 entity out of %{total_count}.",
              "other": "Bulk action completed successfully. Processed %{count} entities out of %{total_count}.",
              "zero": "Bulk action completed successfully. Processed 0 entities out of %{total_count}."
            },
            "errors": {
              "one": "1 error occurred",
              "other": "%{count} errors occurred",
              "zero": "0 errors occurred"
            },
            "select_all_link": "Select all %{count} Sublocations",
            "select_all_selected_html": "All <strong>%{selected_sublocations_count}</strong> Sublocations on this page are selected."
          }
        }
      },
      "devices": {
        "shared": {
          "bulk_action_buttons": {
            "archive_devices": {
              "one": "Archive Asset",
              "other": "Archive %{count} Assets"
            },
            "clear_selection": "Clear Selection",
            "edit_devices": {
              "one": "Edit Asset",
              "other": "Edit %{count} Assets"
            },
            "unassign_devices": {
              "one": "Unassign Asset",
              "other": "Unassign %{count} Assets"
            }
          }
        }
      },
      "inspections": {
        "bulk_action_buttons": {
          "clear_selection": "Clear Selection",
          "complete_reminders": {
            "one": "Complete Reminder",
            "other": "Complete %{count} Reminders"
          },
          "edit_reminders": {
            "one": "Edit Reminder",
            "other": "Edit %{count} Reminders"
          },
          "remove_reminders": {
            "one": "Remove Reminder",
            "other": "Remove %{count} Reminders"
          }
        }
      },
      "leased_assets": {
        "bulk_action_buttons": {
          "review_assets": {
            "one": "Review Lease",
            "other": "Review %{count} Leases",
            "zero": "Review Lease"
          }
        }
      },
      "settings": {
        "autoassign_rules": {
          "shared": {
            "bulk_action_buttons": {
              "bulk_destroy": {
                "one": "Remove 1 Rule",
                "other": "Remove %{count} Rules",
                "zero": "Remove 0 Rule"
              }
            }
          }
        },
        "ui_messages": {
          "are_you_sure_to_exit_without_saving": "The form has unsaved changes. Are you sure you want to leave the page?"
        }
      },
      "shared": {
        "bulk_action_progress_bar": {
          "app_detected_users": {
            "one": "Completed for 1 User out of %{total_count}",
            "other": "Completed for %{count} Users out of %{total_count}",
            "zero": "Completed for 0 Users out of %{total_count}"
          },
          "asset_sources": {
            "one": "Completed for 1 Asset Source out of %{total_count}",
            "other": "Completed for %{count} Asset Sources out of %{total_count}",
            "zero": "Completed for 0 Asset Sources out of %{total_count}"
          },
          "devices": {
            "one": "Completed for 1 Asset out of %{total_count}",
            "other": "Completed for %{count} Assets out of %{total_count}",
            "zero": "Completed for 0 Assets out of %{total_count}"
          },
          "inspection_types": {
            "one": "Completed for 1 Reminder Type out of %{total_count}",
            "other": "Completed for %{count} Reminder Types out of %{total_count}",
            "zero": "Completed for 0 Reminder Types out of %{total_count}"
          },
          "inspections": {
            "one": "Completed for 1 Reminder out of %{total_count}",
            "other": "Completed for %{count} Reminders out of %{total_count}",
            "zero": "Completed for 0 Reminders out of %{total_count}"
          },
          "leased_assets": {
            "one": "Completed for 1 Asset out of %{total_count}",
            "other": "Completed for %{count} Assets out of %{total_count}",
            "zero": "Completed for 0 Assets out of %{total_count}"
          },
          "surveys": {
            "one": "Completed for 1 Survey out of %{total_count}",
            "other": "Completed for %{count} Surveys out of %{total_count}",
            "zero": "Completed for 0 Surveys out of %{total_count}"
          }
        },
        "bulk_action_progress_panel": {
          "app_detected_users": {
            "completed_for": {
              "one": "Bulk action completed successfully. Processed 1 User out of %{total_count}.",
              "other": "Bulk action completed successfully. Processed %{count} Users out of %{total_count}.",
              "zero": "Bulk action completed successfully. Processed 0 Users out of %{total_count}."
            },
            "errors": {
              "one": "1 error occurred",
              "other": "%{count} errors occurred",
              "zero": "0 errors occurred"
            },
            "select_all_link": "Select all %{count}",
            "select_all_selected_html": {
              "one": "<strong>%{count}</strong> User is selected.",
              "other": "<strong>%{count}</strong> Users are selected."
            }
          },
          "asset_sources": {
            "completed_for": {
              "one": "Bulk action completed successfully. Processed 1 Asset Source out of %{total_count}.",
              "other": "Bulk action completed successfully. Processed %{count} Asset Sources out of %{total_count}.",
              "zero": "Bulk action completed successfully. Processed 0 Asset Sources out of %{total_count}"
            },
            "select_all_link": "Select all %{count}",
            "select_all_selected_html": {
              "one": "<strong>%{count}</strong> Asset Source is selected.",
              "other": "<strong>%{count}</strong> Asset Sources are selected."
            }
          },
          "devices": {
            "completed_for": {
              "one": "Bulk action completed successfully. Processed 1 Asset out of %{total_count}.",
              "other": "Bulk action completed successfully. Processed %{count} Asset out of %{total_count}.",
              "zero": "Bulk action completed successfully. Processed 0 Asset out of %{total_count}."
            },
            "errors": {
              "one": "1 error occurred",
              "other": "%{count} errors occurred",
              "zero": "0 errors occurred"
            },
            "select_all_link": "Select all %{count} Assets",
            "select_all_selected_html": {
              "one": "<strong>%{count}</strong> Asset is selected.",
              "other": "All <strong>%{count}</strong> Assets on this page are selected."
            }
          },
          "inspection_types": {
            "completed_for": {
              "one": "Bulk action completed successfully. Processed 1 reminder out of %{total_count}.",
              "other": "Bulk action completed successfully. Processed %{count} reminder out of %{total_count}.",
              "zero": "Bulk action completed successfully. Processed 0 reminder out of %{total_count}."
            },
            "errors": {
              "one": "1 error occurred",
              "other": "%{count} errors occurred",
              "zero": "0 errors occurred"
            },
            "select_all_link": "Select all %{count}",
            "select_all_selected_html": {
              "one": "<strong>%{count}</strong> reminder is selected.",
              "other": "<strong>%{count}</strong> reminders are selected."
            }
          },
          "inspections": {
            "completed_for": {
              "one": "Bulk action completed successfully. Processed 1 Reminder out of %{total_count}.",
              "other": "Bulk action completed successfully. Processed %{count} Reminder out of %{total_count}.",
              "zero": "Bulk action completed successfully. Processed 0 Reminder out of %{total_count}."
            },
            "errors": {
              "one": "1 error occurred",
              "other": "%{count} errors occurred",
              "zero": "0 errors occurred"
            },
            "select_all_link": "Select all %{count} Reminders",
            "select_all_selected_html": {
              "one": "<strong>%{count}</strong> Reminder is selected.",
              "other": "<strong>%{count}</strong> Reminders are selected."
            }
          },
          "leased_assets": {
            "completed_for": {
              "one": "Bulk action completed successfully. Processed 1 Asset out of %{total_count}.",
              "other": "Bulk action completed successfully. Processed %{count} Assets out of %{total_count}.",
              "zero": "Bulk action completed successfully. Processed 0 Assets out of %{total_count}"
            },
            "errors": {
              "one": "1 error occurred",
              "other": "%{count} errors occurred",
              "zero": "0 errors occurred"
            },
            "select_all_link": "Select all %{count}",
            "select_all_selected_html": {
              "one": "<strong>%{count}</strong> Asset is selected.",
              "other": "<strong>%{count}</strong> Assets are selected."
            }
          },
          "surveys": {
            "completed_for": {
              "one": "Bulk action completed successfully. Processed 1 Survey out of %{total_count}.",
              "other": "Bulk action completed successfully. Processed %{count} Surveys out of %{total_count}.",
              "zero": "Bulk action completed successfully. Processed 0 Surveys out of %{total_count}"
            },
            "errors": {
              "one": "1 error occurred",
              "other": "%{count} errors occurred",
              "zero": "0 errors occurred"
            },
            "select_all_link": "Select all %{count} Surveys",
            "select_all_selected_html": {
              "one": "<strong>%{count}</strong> Survey is selected.",
              "other": "<strong>%{count}</strong> Surveys are selected."
            }
          }
        }
      },
      "surveys": {
        "bulk_action_buttons": {
          "cancel_surveys": {
            "one": "Cancel 1 Survey",
            "other": "Cancel %{count} Surveys",
            "zero": "Cancel 0 Surveys"
          },
          "create_surveys": {
            "one": "Survey 1 Selected",
            "other": "Survey %{count} Selected",
            "zero": "Survey 0 selected"
          },
          "remind_surveys": {
            "one": "Remind 1 Survey",
            "other": "Remind %{count} Surveys",
            "zero": "Remind 0 Surveys"
          }
        },
        "filters_index": {
          "are_you_sure_to_request_surveys_count": "Are you sure you want to request %{count} surveys",
          "departments": "Departments",
          "export_spreadsheet": "Export Spreadsheet",
          "filter": "Filter",
          "legal_entities": "Legal Entities",
          "locations": "Locations",
          "sort": "Sort",
          "sorted_by": "Sorted by",
          "survey_selected": "Survey Selected",
          "survey_selected_count": "Survey %{count} Selected",
          "survey_types": "Survey Types"
        }
      },
      "users": {
        "shared": {
          "bulk_action_buttons": {
            "bulk_archive": {
              "one": "Archive 1 Person",
              "other": "Archive %{count} People",
              "zero": "Archive 0 People"
            },
            "bulk_convert_to_service_account": {
              "one": "Convert 1 Person to Service Account",
              "other": "Convert %{count} People to Service Accounts",
              "zero": "Convert 0 People to Service Accounts"
            },
            "bulk_edit": {
              "one": "Edit 1 Person",
              "other": "Edit %{count} People",
              "zero": "Edit 0 People"
            },
            "bulk_invite": {
              "one": "Send 1 Invitation Email",
              "other": "Send %{count} Invitation Emails",
              "zero": "Send 0 Invitation Emails"
            },
            "bulk_unassign": {
              "one": "Unassign 1 Person",
              "other": "Unassign %{count} People",
              "zero": "Unassign 0 People"
            }
          },
          "bulk_action_progress_panel": {
            "completed_for": {
              "one": "Bulk action completed successfully. Processed 1 entity out of %{total_count}.",
              "other": "Bulk action completed successfully. Processed %{count} entities out of %{total_count}.",
              "zero": "Bulk action completed successfully. Processed 0 entities out of %{total_count}."
            },
            "errors": {
              "one": "1 error occurred",
              "other": "%{count} errors occurred",
              "zero": "0 errors occurred"
            },
            "select_all_link": "Select all %{count} People",
            "select_all_selected_html": "All <strong>%{selected_users_count}</strong> People on this page are selected."
          }
        }
      }
    }
  }
}